@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .Home {
        position: absolute;
        width: 100%;
        padding: 0 0 0 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;


        .CanvasWrapper {
            height: 100vh;
            width: 100%;
            cursor: pointer;
        }

    }

    .MichaelHochreiter {
        position: fixed;
        top: 20vh;
        left: 40vw;

        max-width: 300px;

        pointer-events: none;

        z-index: 10;

        margin: 0 0 0 -25vw;

        .Name {
            font-size: 4em;
            line-height: 50px;
            margin: 0;
            font-weight: 400;
        }

        .Title {
            padding: 2px 0 0 3px;
            font-size: 1.7em;
            margin: 0;
            font-weight: 400;
        }
    }

    @keyframes wiggle2 {
        0% { transform: translate(110px, 180px) rotate(6deg); }
       80% { transform: translate(110px, 180px) rotate(6deg); }
       85% { transform: translate(110px, 180px) rotate(14deg); }
       95% { transform: translate(110px, 180px) rotate(-2deg); }
      100% { transform: translate(110px, 180px) rotate(6deg); }
    }

    .Hint {
        position: absolute;
        top: 38vh;
        z-index: 19;
        font-weight: 600;
        font-style: italic;
        font-size: 1.4em;
        animation-iteration-count: infinite;
        animation: wiggle2 3s infinite;
        pointer-events: none;
        padding: 0 6px 2px 6px;
        border-radius: 10px;
    }

    .noselect {
        -webkit-touch-callout: none;
        /* iOS Safari */
        -webkit-user-select: none;
        /* Safari */
        -khtml-user-select: none;
        /* Konqueror HTML */
        -moz-user-select: none;
        /* Old versions of Firefox */
        -ms-user-select: none;
        /* Internet Explorer/Edge */
        user-select: none;
        /* Non-prefixed version, currently
                                        supported by Chrome, Edge, Opera and Firefox */
    }

}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .Home {
        position: relative;
        width: 85vw;
    }

    .MichaelHochreiter {
        position: fixed;
        top: 17vh;
        left: 50vw;
    }

    @keyframes wiggle2 {
        0% { transform: translate(120px, 130px) rotate(6deg); }
       80% { transform: translate(120px, 130px) rotate(6deg); }
       85% { transform: translate(120px, 130px) rotate(14deg); }
       95% { transform: translate(120px, 130px) rotate(-2deg); }
      100% { transform: translate(120px, 130px) rotate(6deg); }
    }

}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {

    .Home {
        width: 90vw;
    }

}

/* --------------------------------- THEMES --------------------------------- */

.Home.Dark {
    background-color: $dark-backgroundColor2;

    .Hint {        
        color: $light-backgroundColor;        
        filter: drop-shadow(1px 1px 2px $light-text5)

    }

    .MichaelHochreiter {
        color: $dark-text1;
    }

}

.Home.Light {
    background-color: $light-backgroundColor;

    .Hint {        
        color: $light-backgroundColor;        
        filter: drop-shadow(1px 1px 2px $light-text5)

    }

    .MichaelHochreiter {
        color: $light-text1;
    }
}