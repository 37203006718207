@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {
    .Navbar {
        position: fixed;
        z-index: 20;
        width: 100%;
        top: 0;

        .burger {
            position: absolute;
            top: 32px;
            right: 36px;
            padding: 8px 4px;

            .box0,
            .box1 {
                height: 4px;
                width: 40px;
                margin: 6px 0 6px 0;
                position: relative;

            }
        }

        ul {
            margin: 24vh 40px 0 50px;
            padding: 0;

            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;

            list-style: none;
            font-size: 4em;

            a.selected {
                font-weight: 600;
            }

            a.notSelected {
                font-weight: 200;
            }

            :link,
            :visited,
            :hover,
            :active {
                text-decoration: none;
            }

            li {
                height: 90px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                cursor: pointer;

            }

            li.kleeblatt {

                img,
                svg {
                    width: 45px;
                    padding-top: 12px;
                }
            }

        }
    }

    .Inactive {

        .box0,
        .box1 {
            transform: translate(0px, 0px) rotate(0deg);
            transition: transform 0.2s;
        }

    }

    .Active {
        backdrop-filter: blur(40px);
        width: 100%;
        height: 100%;


        .box0 {
            transform: translate(0px, 5px) rotate(45deg);
            transition: transform 0.2s;
        }

        .box1 {
            transform: translate(0px, -5px) rotate(-45deg);
            transition: transform 0.2s;
        }
    }
}


/* --------------------------------- TABLET --------------------------------- */
// @media (min-width:581px) {}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {
    .Navbar {
        width: 15vw;
        height: 100%;
        left: 0;
        font-size: 0.4em;


        ul {
            margin: 2.6vw 3vw 3vw 3vw;
            padding: 0;

            justify-content: flex-start;
            align-items: flex-start;

            li {
                height: 33px;
                transform: translate(0, 0);
                transition: transform 0.2s;
                margin-left: -15px;
                padding-left: 15px;
            }

            li:hover {
                transform: translate(4px, 0);
                transition: transform 0.2s;
            }

            li.kleeblatt {

                img,
                svg {
                    width: 19px;
                    box-sizing: content-box;
                    padding-right: 20px;
                }
            }
        }
    }


    .Active,
    .Inactive {
        backdrop-filter: none;
    }
}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {
    .Navbar {
        width: 10vw;

        ul {
            margin: 1.8vw 2vw 2vw 2vw;
        }
    }
}


/* --------------------------------- THEMES --------------------------------- */


@media (min-width:320px) {

    .Navbar.Dark {

        .burger .box0,
        .box1 {
            background-color: $dark-text1;
        }

        :link,
        :visited,
        :hover {
            color: $dark-text1;
        }

        a:active {
            color: $dark-highlight1  !important;
        }

        a.selected {
            color: $dark-text1;
        }

        a.notSelected {
            color: $dark-text2;
        }


        .kleeblatt .selected svg {
            // filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(265deg) brightness(110%) contrast(98%);
            filter: invert(100%);
        }


        .kleeblatt .notSelected svg {
            // filter: invert(86%) sepia(99%) saturate(7292%) hue-rotate(189deg) brightness(143%) contrast(84%);
            filter: invert(86%);

        }

        .kleeblatt:active {
            svg {
                filter: invert(23%) sepia(17%) saturate(6251%) hue-rotate(235deg) brightness(93%) contrast(96%);
            }
        }
    }


    .Navbar.Light {

        .burger .box0,
        .box1 {
            background-color: $light-text1;

        }

        :link,
        :visited,
        :hover {
            color: $light-text1;
        }

        a:active {
            color: $light-highlight2  !important;
        }

        a.selected {
            color: $light-text1;
        }

        a.notSelected {
            color: $light-text2;
        }


        .kleeblatt .selected svg {
            // filter: invert(0%) sepia(97%) saturate(24%) hue-rotate(102deg) brightness(94%) contrast(97%);

        }


        .kleeblatt .notSelected svg {
            // filter: invert(7%) sepia(3%) saturate(19%) hue-rotate(330deg) brightness(96%) contrast(96%);
        }

        .kleeblatt:active {
            svg {
                filter: invert(65%) sepia(52%) saturate(3731%) hue-rotate(93deg) brightness(105%) contrast(94%);
            }
        }
    }
}

@media (min-width:781px) {

    .Navbar.Dark {
        background-color: $dark-backgroundColor1-5;
    }

    .Navbar.Light {
        background-color: $light-backgroundColor1-5;
    }
}