$dark-backgroundColor: #191919;
$dark-backgroundColor1-5: rgb(16, 16, 16);
$dark-backgroundColor2: rgb(12, 12, 12);
$dark-backgroundColor-2: rgb(35, 35, 35);
$dark-text1: #ffffff;
$dark-text2: rgb(235, 235, 235);
$dark-text3: rgb(200, 200, 200);
$dark-text4: rgb(165, 165, 165);
$dark-text5: #7b7b7b;
$dark-text7: #333333;
$dark-highlight1: rgb(118, 86, 235);
$dark-highlight2: #5e43be;
$dark-highlight3: #342569;

$light-backgroundColor: #f6f6f6;
$light-backgroundColor1-5: rgb(235, 235, 235);
$light-backgroundColor2: rgb(225, 225, 225);
$light-backgroundColor-2: rgb(235, 235, 235);
$light-text1: #050505;
$light-text2: rgb(23, 23, 23);
$light-text3: rgb(31, 31, 31);
$light-text4: rgb(44, 44, 44);
$light-text5: #717171;
$light-text7: #a9a9a9;
$light-highlight1: #00ff51;
$light-highlight2: #08ca46;
$light-highlight3: #0b8832;
