@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {
    .Project {
        height: min-content;
        margin: 0px 20px -60px 20px;
    }

    .Project img {
        width: 100%;
        border-radius: 8px;
        transition: transform 0.2s;

    }

    .Project .Thumbnail img {
        cursor: pointer;
    }

    .Project .Thumbnail img:hover{
        transform: scale(1.015, 1.015);
        transition: transform 0.2s;
    }

    .ThumbnailInfo {
        margin: 3px 5px 5px 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        font-size: 1.2em;
        
        cursor: pointer;

        .Title,
        .Year {
            margin: 0;
            font-weight: 400;
        }

        .Year {
            font-size: 0.7em;
            padding: 0 0 3px 6px;
        }
    }

    .Thumbnail {
        z-index: 10;
        position: relative;



    }

    .Details {
        position: relative;
        top: -100px;
        margin: 0;
        padding: 100px 20px 0px 20px;
        border-radius: 8px;

        .Info {
            padding-bottom: 10px;

            .Description {
                margin-top: 10px;
                margin-bottom: 0px;
                font-weight: 400;
                font-size: 1.05em;
            }

            .Link {
                margin: 7px 0 0 0;
            }
        }

        .Detail_0,
        .Detail_1 {
            margin: 20px 0px 20px 0px;
        }

        .Software,
        .Role {
            font-style: italic;
            margin: 5px 0 7px 0;
        }

        .SoftwareSpan,
        .RoleSpan {
            font-weight: 600;
            font-style: normal;
        }

    }

    .Expanded {
        .Details {
            overflow: hidden;
            max-height: 2000px;
            opacity: 1;
            transition: max-height 0.6s, opacity 0.15s;
        }
    }

    .NotExpanded {
        .Details {
            overflow: hidden;
            max-height: 0;
            opacity: 0;
            transition: max-height 0.3s, opacity 0.15s;
        }
    }

}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:501px) {

    .ThumbnailInfo {
        font-size: 1.5em;

        .Year {
            font-size: 0.85em;
        }
    }


    .Details {
        .Images {
            display: grid;

        }

        .Info {
            padding-bottom: 10px;
        }

        .Images {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 20px;
            padding: 20px 0 20px 0;
        }

        .Detail_0 {
            grid-column: 2;
            grid-row: 1;
        }

        .Detail_1 {
            grid-column: 1;
            grid-row: 1;
        }

        .Detail_0,
        .Detail_1 {
            margin: 0;
        }

    }
}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .Project {
        margin: 0px 0px -60px 0px;
    }

    .Project img {
        border-radius: 2px;
    }

    .Details {
        border-radius: 2px;
    }

    .ThumbnailInfo {
        font-size: 1.1em;

        .Year {
            font-size: 0.8em;
            padding: 0 0 2px 6px;
        }
    }
}

/* --------------------------------- LAPTOP --------------------------------- */
// @media (min-width:1001px) {}

/* --------------------------------- BIG SCREEN --------------------------------- */
@media (min-width:1281px) {

    .Project {
        .Title {
            font-size: 1.6em;
        }
        .Details {
            .Description {
                font-size: 1.2em;
            }
        }
    }
}



/* --------------------------------- THEMES --------------------------------- */

.Project.Dark {
    .Thumbnail {
        color: $dark-text1;

        .Year {
            color: $dark-text5;
        }
    }

    .Details {
        background-color: $dark-backgroundColor-2;

        .Description {
            color: $dark-text2;
        }

        .Software,
        .Role {
            color: $dark-text4;
        }

        .Link {
            color: $dark-text2;
        }
    }

}

.Project.Light {
    .Thumbnail {
        color: $light-text1;

        .Year {
            color: $light-text5;
        }
    }

    .Details {
        background-color: $light-backgroundColor-2;

        .Description {
            color: $light-text2;
        }

        .Software,
        .Role {
            color: $light-text4;
        }

        .Link {
            color: $light-text2;
        }
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    a:active {
        text-decoration: none;
        filter: brightness(60%);
    }
}