@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .ThemeSwitch {
        padding: 0;
        border: 0;
        z-index: 10;

        position: absolute;
        bottom: 0;
        left: 0;
        margin: 40px;
        width: 100px;

        cursor: pointer;

        .Wrapper {
            display: flex;
            justify-content: space-between;
            cursor: pointer;
        }


        .Bar {
            height: 3px;
            width: 46px;
            margin-left: 1px;
        }
    }

    .Button {
        font-size: 1.45em;
        font-family: 'Degular';
        font-weight: 600;
        margin: 0;
        border: none;
        padding: 0;
        cursor: pointer;
    }


    .ThemeSwitch.Dark {
        .Bar {
            transform: translate(54px, 0px);
            width: 45px;
            transition: all 0.2s;
        }
    }

    .ThemeSwitch.Light {
        .Bar {
            transform: translate(0px, 0px);
            transition: all 0.2s;
        }
    }
}

/* --------------------------------- TABLET --------------------------------- */
// @media (min-width:581px) {}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .ThemeSwitch {
        left: 0;
        bottom: 0;
        margin: 3vw 3vw 2.5vw 3vw;
        width: 68px;

        .Bar {
            height: 2px;
            width: 30px;
            margin-left: 1px;
        }
    }

    .Button {
        font-size: 2.35em;
    }


    .ThemeSwitch.Dark {
        .Bar {
            transform: translate(38px, 0px);
            width: 30px;
            transition: all 0.2s;
        }
    }

    .ThemeSwitch.Light {
        .Bar {
            transform: translate(0px, 0px);
            transition: all 0.2s;
        }
    }
}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {

    .ThemeSwitch {
        margin: 2vw 2vw 1.66vw 2vw;
        width: 64px;

        .Bar {
            height: 2px;
            width: 28px;
            margin-left: 1px;
        }
    }

    .Button {
        font-size: 2.2em;
    }


    .ThemeSwitch.Dark {
        .Bar {
            transform: translate(36px, 0px);
            width: 28px;
            transition: all 0.2s;
        }
    }

    .ThemeSwitch.Light {
        .Bar {
            transform: translate(0px, 0px);
            transition: all 0.2s;
        }
    }
}

/* --------------------------------- BIG SCREEN --------------------------------- */
// @media (min-width:1281px) {}

/* --------------------------------- THEMES --------------------------------- */

.ThemeSwitch.Dark {
    background-color: transparent;

    .Bar {
        background-color: $dark-text1;
    }

    .Button {
        color: $dark-text1;
        background-color: transparent;
    }
}

.ThemeSwitch.Light {
    background-color: transparent;

    .Bar {
        background-color: $light-text1;
    }

    .Button {
        color: $light-text1;
        background-color: transparent;
    }
}

.ThemeSwitch button.L:active {
    color: $light-highlight2;
}

.ThemeSwitch button.D:active {
    color: $dark-highlight1;
}