@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .Work {
        position: absolute;
        width: 100%;
        padding: 0 0 0 0;

        .Intro {
            padding: 35px 120px 60px 40px;

            p {
                font-size: 2em;
                margin: 0 0 0 0;
            }
        }
    }

}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:581px) {

    .Work {
        .Intro {
            padding-right: 180px;

            p {
                font-size: 3.1em;
            }
        }
    }

}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .Work {
        position: relative;
        width: 85vw;

        .Intro {
            padding: 30px 30px 38px 30px;
        }

        .gridContainer {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px;
            margin: 0 30px 0 30px;
        }

        .leftContainer {
            grid-column: 1;
            grid-row: 1;
        }

        .rightContainer {
            grid-column: 2;
            grid-row: 1;
        }
    }

}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {
    .Work {
        width: 90vw;
    }
}

/* --------------------------------- BIG SCREEN --------------------------------- */
@media (min-width:1281px) {

    .Work {

        .gridContainer {
            grid-template-columns: repeat(3, 1fr);
        }

        .leftContainer {
            grid-column: 1;
            grid-row: 1;
        }

        .middleContainer {
            grid-column: 2;
            grid-row: 1;
        }

        .rightContainer {
            grid-column: 3;
            grid-row: 1;
        }
    }
}

/* --------------------------------- THEMES --------------------------------- */

.Work.Dark {
    background-color: $dark-backgroundColor;

    p {
        color: $dark-text2;
    }
}

.Work.Light {
    background-color: $light-backgroundColor;

    p {
        color: $light-text2;
    }
}