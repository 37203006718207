@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .About {
    
        position: absolute;

        .Portrait {
            width: 100%;
            position: fixed;
            margin: 0 0 0 0;
            top: -5vh;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;


            @keyframes wiggle {
                0% { transform: translate(-80px, 0px) rotate(6deg); }
               80% { transform: translate(-80px, 0px) rotate(6deg); }
               85% { transform: translate(-80px, 0px) rotate(14deg); }
               95% { transform: translate(-80px, 0px) rotate(-2deg); }
              100% { transform: translate(-80px, 0px) rotate(6deg); }
            }

            .Hint {
                position: absolute;
                top: 38vh;
                z-index: 20;
                font-weight: 600;
                font-style: italic;
                font-size: 1.4em;
                animation-iteration-count: infinite;
                animation: wiggle 3s infinite;
                pointer-events: none;
                padding: 0 6px 2px 6px;
                border-radius: 10px;
            }

            .CanvasWrapper {
                background-color: transparent;

                box-sizing: content-box;
                height: 50vh;
                width: 100%;

                cursor: move;
            }
        }

    

        .Bio {
            margin: 43vh 20px 20px 20px;
            padding: 20px 20px 200px 20px;

            border-radius: 12px;
            background-color: transparent;
            backdrop-filter: blur(40px);

            .PullBarWrapper {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }

            .PullBar {
                height: 4px;
                width: 40%;
                margin: 0 0 4px 0;
                padding: 0;
                border-radius: 2px;
            }

            h1 {
                font-size: 11vw;
                font-weight: 600;
                margin-bottom: 0;
                margin-top: 0;
            }

            h3 {
                font-size: 1.5em;
                font-weight: 600;
                margin-top: 40px;
                margin-bottom: 10px;
            }

            h4 {
                font-size: 1.4em;
                font-weight: 600;
                margin-top: 4px;
                margin-bottom: 60px;
            }

            p {
                font-size: 1.2em;
                font-weight: 400;
                margin: 0;
            }

            span.C {
                font-size: 1.6em;
                font-weight: 400;
            }

            span.Book {
                font-style: italic;
            }
        }
    }
}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:581px) {

    .About {
        .Bio {

            h1 {
                margin-bottom: 0;
            }

            h3 {
                font-size: 1.6em;
            }

            h4 {
                font-size: 1.5em;
                margin-top: 0px;
                margin-bottom: 70px;
            }

            p {
                font-size: 1.25em;
            }

            span.C {
                font-size: 1.6em;
            }
        }
    }
}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .About {
        position: relative;

        .Portrait {
            width: 85vw;
        }

        .Bio {
            margin: 43vh 20px 20px 20px;

            h1 {
                font-size: 9vw;

            }

            h3 {
                font-size: 1.5em;

            }

            h4 {
                font-size: 1.3em;
                margin-bottom: 60px;

            }

            p {
                font-size: 1.2em;

            }

            span.C {
                font-size: 1.6em;

            }
        }
    }
}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {

    .About {
        width: 90vw;

        .Portrait {
            top: -6vh;
            right: -20vw;
            flex-direction: column;
            justify-content: center;
            width: min-content;

            .CanvasWrapper {
                width: 80vw;
                height: 100vh;
            }

            @keyframes wiggle {
                0% { transform: translate(120px, 0px) rotate(6deg); }
               80% { transform: translate(120px, 0px) rotate(6deg); }
               85% { transform: translate(120px, 0px) rotate(14deg); }
               95% { transform: translate(120px, 0px) rotate(-2deg); }
              100% { transform: translate(120px, 0px) rotate(6deg); }
            }

            .Hint {
                top: 47vh;

            }
        }

        .Bio {
            position: relative;
            backdrop-filter: none;
            width: 54vw;
            margin-top: 5vw;
            margin-left: 7vw;
            padding-bottom: 8vw;


            h1 {
                font-size: 7vw;

            }

            h3 {
                font-size: 1.6em;

            }

            h4 {
                font-size: 1.5em;
                margin-bottom: 60px;

            }

            p {
                font-size: 1.3em;

            }

            span.C {
                font-size: 1.6em;

            }
        }
    }
}

/* --------------------------------- BIG SCREEN --------------------------------- */
@media (min-width:1281px) {

    .About {
        .Bio {
            width: 44vw;
            margin-top: 5vw;
            margin-left: 9vw;
            padding-bottom: 8vw;

            h1 {
                font-size: 5.8vw;
            }
        }

        .Portrait {
            right: -17vw;       


        }
    }
}

/* --------------------------------- THEMES --------------------------------- */

.About.Dark {
    background-color: $dark-backgroundColor;

    .PullBar {
        background-color: $dark-backgroundColor1-5;
    }

    h1 {
        color: $dark-text1;
    }

    h3 {
        color: $dark-text3;
    }

    h4,
    span {
        color: $dark-text2;
    }

    p,
    .Book {
        color: $dark-text4;
    }

    .Hint {
        color: $dark-highlight1;
        filter: drop-shadow(1px 1px 4px $dark-backgroundColor);

    }

    a {
        color: $dark-highlight1;
        text-decoration: none;
    }

    a:active {
        color: $dark-highlight3;
    }

    a:hover {
        text-decoration: underline;
    }
}

.About.Light {
    background-color: $light-backgroundColor;

    .PullBar {
        background-color: $light-backgroundColor1-5;
    }


    h1 {
        color: $light-text1;
    }

    h3 {
        color: $light-text3;
    }

    h4,
    span {
        color: $light-text2;
    }

    p,
    .Book {
        color: $light-text4;
    }

    .Hint {        
        color: $light-highlight2;        
        filter: drop-shadow(1px 1px 2px $light-backgroundColor)

    }

    a {
        color: $light-highlight2;
        text-decoration: none;
    }

    a:active {
        color: $light-highlight3;
    }

    a:hover {
        text-decoration: underline;
    }

}