@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .Footer {
        text-align: center;
        font-size: 0.8rem;
        padding: 40px 40px 25px 40px;

        img {
            height: 20px;
            margin-top: 5px;
        }


    }




    
}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:581px) {

    .Footer {
        font-size: 1.1em;

        img {
            height: 27px;
        }
    }

}

/* --------------------------------- WIDE TABLET --------------------------------- */
// @media (min-width:781px)  {}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {

    .Footer {
        font-size: 0.8em;
        padding: 20px 0;

        img{
            height: 18px;
        }
    }

 

}

/* --------------------------------- BIG SCREEN --------------------------------- */
// @media (min-width:1281px) {}


/* --------------------------------- THEMES --------------------------------- */

.Footer.Dark {
    background-color: $dark-backgroundColor2;
    color: $dark-text5;

    img {
        filter: invert(54%) sepia(1%) saturate(1673%) hue-rotate(315deg) brightness(88%) contrast(83%);
    }
}

Footer.Light {
    background-color: $light-backgroundColor2;
    color: $light-text5;

    img {
        filter: invert(47%) sepia(0%) saturate(3590%) hue-rotate(332deg) brightness(95%) contrast(91%);
    }
}
