@import './Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {

    .ControlsWrapper {
        position: fixed;
        bottom: 0px;

        height: 100px;
        width: 100%;

        padding: 20px;

        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .Controls {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        max-width: 400px;

    }

    .Questionmark {
        height: 46px;
        width: 46px;
        margin: 12px;

        font-size: 3em;

        border-radius: 8px;


        cursor: pointer;

        .IconWrapper {
            transform: translate(11px, -12px);
        }

    }



    .Brushes {
        height: 60px;
        width: 180px;

        display: flex;
        flex-direction: row;

        border-radius: 8px;

        z-index: 0;

        cursor: pointer;

        .IconWrapper {
            padding: 6px;
            height: 60px;
            width: 60px;


        }

        .Bar {
            width: 54px;
            height: 54px;
            margin: 3px;
            position: absolute;

            z-index: -5;

            transition: transform 0.15s;

            border-radius: 8px;
        }

        .Bar.klee {
            transform: translate(0, 0);
        }

        .Bar.mushroom {
            transform: translate(60px, 0);
        }

        .Bar.dandelion {
            transform: translate(120px, 0);
        }
    }

    .Clear {
        height: 46px;
        width: 46px;
        margin: 12px;

        cursor: pointer;

        border-radius: 8px;

        .ClearBar {
            height: 3px;
            margin: 0 7px 0 7px;
        }

        .b1 {
            transform: rotate(45deg);
        }

        .b2 {
            transform: translate(0, -3px) rotate(-45deg);
        }

        .IconWrapper {
            transform: translate(0, 22px);
        }

    }

    .Controls svg {
        border-radius: 6px;
    }

    .HelpText {
        position: fixed;

        height: 100%;
        width: 100%;

        z-index: 10;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        pointer-events: none;

        .HelpTextWrapper {
            margin: 0 0 100px 0;
            padding: 4px 10px 7px 10px;
            border-radius: 10px;
            
            text-align: center;
            font-size: 1.3em;
            font-weight: 400;
        }

        p.ControlsPlanet {
            margin: 0 0 14px 0;

        }

        p.ControlsLifeForm {
            margin: 0;
        }
    }

}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:581px) {}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .ControlsWrapper {
        position: fixed;
        right: 0px;

        height: 100%;
        width: 92px;

        flex-direction: column;

    }

    .Controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        width: 46px;
        height: 100%;
        max-height: 270px;
    }


    .Questionmark {
        height: 34px;
        width: 34px;
        margin: 6px;

        font-size: 2em;
        font-weight: 400;

        .IconWrapper {
            transform: translate(10px, -6px);
        }
    }


    .Brushes {
        height: 138px;
        width: 46px;

        flex-direction: column;

        border-radius: 8px;

        .IconWrapper {
            padding: 5px;
            height: 46px;
            width: 46px;
        }

        .Bar {
            width: 40px;
            height: 40px;
            margin: 3px;

            border-radius: 6px;
        }

        .Bar.klee {
            transform: translate(0, -1px);

        }

        .Bar.mushroom {
            transform: translate(0, 46px);
        }

        .Bar.dandelion {
            transform: translate(0, 92px);
        }
    }

    .Clear {
        height: 34px;
        width: 34px;
        margin: 6px;

        .ClearBar {
            height: 2px;
            margin: 0 7px 0 7px;
        }

        .b1 {
            transform: rotate(45deg);
        }

        .b2 {
            transform: translate(0, -2px) rotate(-45deg);
        }

        .IconWrapper {
            transform: translate(0px, 17px);
        }
    }

    .HelpText {
        width: 85vw;
        flex-direction: row;

        .HelpTextWrapper {
            margin: 0 100px 0 0;
        }

    }

}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {

    .HelpText {
        width: 90vw;
    }
}

/* --------------------------------- BIG SCREEN --------------------------------- */
@media (min-width:1281px) {}

/* --------------------------------- THEMES --------------------------------- */

.Controls.Dark {
    .Brushes {
        background-color: $dark-backgroundColor1-5;
    }


    .Questionmark {
        color: $dark-text2;

        background-color: $dark-backgroundColor1-5;
    }

    .Questionmark:active {
        color: $dark-text2;
    }

    .ClearBar {
        background-color: $dark-text2;
    }

    .Clear:active {
        .ClearBar {
            background-color: $dark-text2;
        }
    }

    .Bar {
        background-color: $dark-text1;
    }

    .Clear {
        background-color: $dark-backgroundColor1-5;
    }
}

.HelpText.Dark {
    color: $dark-text2;

    .HelpTextWrapper {
        backdrop-filter: blur(20px);
    }
}

.Controls.Light {
    .Brushes {
        background-color: $light-backgroundColor1-5;
    }

    .Questionmark {
        color: $light-text2;
        background-color: $light-backgroundColor1-5;
    }

    .Questionmark:active {
        color: $light-text2;
    }

    .ClearBar {
        background-color: $light-text2;
    }

    .Clear:active {
        .ClearBar {
            background-color: $light-text2;
        }
    }

    .Bar {
        background-color: $light-text1;
    }

    .Clear {
        background-color: $light-backgroundColor1-5;
    }

}

.HelpText.Light {
    color: $light-text2;

    .HelpTextWrapper {
        backdrop-filter: blur(20px);
    }
}