@import './components/styles/Colors.scss';

/* --------------------------------- MOBILE --------------------------------- */
@media (min-width:320px) {}

/* --------------------------------- TABLET --------------------------------- */
@media (min-width:581px) {}

/* --------------------------------- WIDE TABLET --------------------------------- */
@media (min-width:781px) {

    .App {
        display: grid;
        margin: 0 0 0 0;
        grid-template-areas: 'left right';
        grid-template-columns: 1.5fr 8.5fr;
        grid-gap: 0 0;

        .page {
            grid-area: right;
        }

        .bar {
            grid-area: left;
        }

        .background {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
        }
    }
}

/* --------------------------------- LAPTOP --------------------------------- */
@media (min-width:1001px) {
    .App {
        grid-template-columns: 1fr 9fr;
    }
}

/* --------------------------------- BIG SCREEN --------------------------------- */
@media (min-width:1281px) {}


/* --------------------------------- THEMES --------------------------------- */

.App.Dark {
    background-color: $dark-backgroundColor;

    ::selection {
        background-color: $dark-highlight1;
    }

}

.App.Light {
    background-color: $light-backgroundColor;

    ::selection {
        background-color: $light-highlight1;
    }
}

.darkBody {
    background-color: $dark-backgroundColor;
}

.lightBody {
    background-color: $light-backgroundColor;
}